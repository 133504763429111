<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="12">
            <b-card>
                <b-form @submit.prevent="update">
                  <b-row>

                    <!-- name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="warehouse.name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Code -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Warehouse Code')"
                        label-for="v-code"
                      >
                        <b-form-input
                          id="v-code"
                          :placeholder="$t('Warehouse Code')"
                          v-model="warehouse.code"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Cities -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Cities')"
                        label-for="v-cities"
                      >
                        <v-select
                          v-model="warehouse.cities"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="cities"
                          :reduce="city => city.id"
                          multiple
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Warehouses Related -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Qoyod Warehouse')"
                        label-for="v-warehouses_related"
                      >
                        <b-form-input
                          id="v-warehouses_related"
                          :placeholder="$t('Qoyod Warehouse')"
                          v-model="warehouse.warehouses_related.qoyod_id"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Sort -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Sort')"
                        label-for="v-sort"
                      >
                        <b-form-input
                          id="v-sort"
                          placeholder="1"
                          v-model="warehouse.sort"
                        />
                      </b-form-group>
                    </b-col>
                    
                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  name: 'WarehouseEdit',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      warehouse: {},
      snowOption: {},
      cities: []
    }
  },
  mounted() {
    this.getCities();
    this.getWarehouse();
  },
  methods: {
    getWarehouse(){
        this.isLoading = true
        useJwt.post('/warehouses/get-by-id',{id: this.$route.params.id})
        .then((response) => {
          // console.log(response.data)
          this.warehouse = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    update() {
      this.isLoadingSave = true;
      useJwt.post('/warehouses/update',this.warehouse)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message.title)
      });
    },
    getCities(){
      useJwt.get('/cities/get-all')
      .then((response) => {
        // console.log(response.data)
        this.cities = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
  }
}
</script>
<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
